<template>
  <div class="container">
    <van-cell-group inset :border="false">
      <van-field
        required
        center
        v-model="formData.catName"
        readonly
        label-align="right"
        label-width="75"
        label="问题类型"
        placeholder="请选择问题类型"
        @click="showPicker = true"
      />

      <van-field
        required
        center
        type="digit"
        label-align="right"
        label-width="75"
        v-model="formData.steam_account"
        label="SteamID"
        maxlength="11"
        right-icon="question-o"
        @click-right-icon="showHelper"
        placeholder="请输入SteamID"
      />

      <van-field
        required
        center
        type="tel"
        label-align="right"
        label-width="75"
        v-model="formData.mobile"
        label="手机号"
        maxlength="11"
        placeholder="请输入您的手机号"
      />
      <van-field required center type="digit" maxlength="15" label-align="right" label-width="75" v-model="formData.qq" label="QQ" placeholder="请输入您的QQ" />
      <van-field
        required
        center
        label-align="right"
        type="textarea"
        rows="5"
        label-width="75"
        maxlength="1000"
        autosize
        v-model="formData.problem_desc"
        label="问题描述"
        placeholder="请输入问题描述"
      />
      <div class="upload-cell">
        <div class="upload-title"><span class="title-left" style="width: 75px">问题截图</span> <span style="font-size: 12px">（选填，最多3张）</span></div>
        <div class="upload-list">
          <van-uploader
            upload-icon="photo-o"
            multiple
            :max-count="3"
            :max-size="1024 * 1024 * 10"
            @oversize="oversize"
            v-model="uploadFiles"
            :after-read="afterRead"
          />
        </div>
      </div>
    </van-cell-group>

    <van-button block class="blue-btn" :disabled="subDisabled" style="margin-top: 15px" type="primary" @click="submit">提交反馈</van-button>
    <van-button block class="grey-btn" style="margin-top: 15px" type="primary" @click="router.push({ name: 'FeedbackList' })">查看反馈记录</van-button>

    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker :columns="catList" @confirm="confirmCat" @cancel="showPicker = false" />
    </van-popup>

    <van-dialog class="subscribe-dialog" v-model:show="showHelperDialog" confirm-button-text="关闭" title="如何查找自己的SteamID？">
      <div class="mode-content">
        <div class="model-help">登录Dota2游戏找到下图中的SteamID。</div>
        <img src="/images/user/mode-2-step.jpg" />
      </div>
    </van-dialog>
  </div>
</template>
<script>
export default {
  name: 'Feedback',
}
</script>
<script setup>
import { Field, CellGroup, Cell, Button, Uploader, Toast, Picker, Popup, Dialog } from 'vant'
import { ref, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { getProblemCatList, submitProblemFeedback } from '@/api/problem'
import { getQiniuToken, uploadFile } from '@/utils/upload'

const VanCell = Cell
const VanField = Field
const VanCellGroup = CellGroup
const VanButton = Button
const VanUploader = Uploader
const VanPicker = Picker
const VanPopup = Popup
const VanDialog = Dialog.Component
const store = useStore()
const router = useRouter()

const showHelperDialog = ref(false)
const catList = ref([])
const showPicker = ref(false)
const subDisabled = ref(false)
const uploadFiles = ref([])
const formData = reactive({
  cat_id: 0,
  catName: '',
  mobile: '',
  qq: '',
  open_id: '',
  player_id: 0,
  steam_account: '',
  problem_desc: '',
  problem_pictures: [],
})

onMounted(() => {
  initData()
})

const initData = async () => {
  store.dispatch('user/getInfo').then(res => {
    formData.player_id = res.player_id
  })
  store.dispatch('player/getInfo').then(res => {
    formData.mobile = res.data.playerExtend && res.data.playerExtend.mobile ? res.data.playerExtend.mobile : ''
    formData.steam_account = res.data.player && res.data.player.steam_account ? res.data.player.steam_account : ''
  })
  getQiniuToken()
  let res = await getProblemCatList()
  catList.value = res.data.catList
}

const validate = () => {
  if (!formData.cat_id) {
    Toast('请选择问题类型')
    return false
  }
  if (!formData.mobile) {
    Toast('请输入手机号')
    return false
  }

  let reg = /^(1[1-9][0-9])\d{8}$/
  if (!reg.test(formData.mobile)) {
    Toast('输入的手机号格式不正确')
    return false
  }
  if (!formData.qq) {
    Toast('请输入QQ号')
    return false
  }
  if (!formData.steam_account) {
    Toast('请输入SteamID')
    return false
  }
  if (!formData.problem_desc) {
    Toast('请输入问题描述')
    return false
  }
  return true
}

const submit = async () => {
  if (!validate()) {
    return false
  }

  formData.problem_pictures.length = 0

  uploadFiles.value.forEach(item => {
    formData.problem_pictures.push(item.path)
  })

  let res = await submitProblemFeedback(formData)
  if (res) {
    Toast.success({
      message: res.message,
      forbidClick: true,
      onClose: () => {
        router.replace({ name: 'FeedbackDetail', query: { id: res.data.id } })
      },
    })
  }
}

const oversize = file => {
  // console.log("oversize:", file);
  Toast('图片大小不能超过10MB')
}

const confirmCat = item => {
  formData.cat_id = item.id
  formData.catName = item.text
  showPicker.value = false
}

const afterRead = file => {
  subDisabled.value = true
  uploadFile(file, res => {
    subDisabled.value = false
  })
}
const showHelper = () => {
  showHelperDialog.value = true
}
</script>

<style lang="scss" scoped>
.container {
  padding: 20px;
}

.blue-btn {
  display: block;
  width: 100%;
  margin: 30px auto 0;
}

.upload-cell {
  color: #b0b4c9;
  padding: var(--van-cell-vertical-padding) var(--van-cell-horizontal-padding);
}

.upload-list {
  padding: 10px 5px;
}

.upload-title .title-left {
  text-align: right;
  display: inline-block;
  margin-right: var(--van-field-label-margin-right);
}

.mode-box {
  border-radius: 10px;
  width: 90vw;
  background: #232329;
  overflow: hidden;
}

.mode-title {
  padding: 5px 15px;
  background: #373a44;
  color: #d3d7e5;
  font-size: 14px;
}

.mode-content {
  padding: 5px 15px;
  font-size: 12px;
  font-weight: 500;
  color: #b0b4c9;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.model-help {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
}

.mode-content img {
  border-radius: 10px;
  margin: 15px 0;
  width: 100%;
}

.upload-title {
  font-size: var(--van-cell-font-size);
  line-height: var(--van-cell-line-height);
}
</style>
